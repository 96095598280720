import React from "react";
import PropTypes from "prop-types";

NumberHelperText.propTypes = {templateKey: PropTypes.any};

export function NumberHelperText({templateKey}) {
  return <span className={ "has-text-white-ter has-shadow" }>
    { (() => {
      if (templateKey === "index-page") {
        return "(pelkkä pvm/mpm)"
      } else {
        return "(local or mobile network charge)"
      }
    })() }
  </span>;
}
