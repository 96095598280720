import React from 'react'
import PropTypes from 'prop-types'
import { chunk } from "lodash";
import { faCar, faRoad, faUsers, faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PricingFrontPage = ({ data }) => {
  const chunks = chunk(data,1);
  console.log(data)
  return (
    chunks.map((chunkItem) => (
      chunkItem.map((price) => (
        [<nav className="level level-is-shrinkable" key={price.plan}>
          <div className="level-item has-text-centered">
            <div>
              <p className="text-gold heading is-size-6">{ price.header4 }</p>
              <p className="text-gold title">
                <FontAwesomeIcon icon={ faUsers }/>
                { price.description2 }
              </p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="text-gold heading is-size-6">{ price.header2 }</p>
              <p className="text-gold title">
                <FontAwesomeIcon icon={ faRoad }/>
                { price.price }
              </p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="text-gold heading is-size-6">{ price.header3 }</p>
              <p className="text-gold title">
                <FontAwesomeIcon icon={ faClock }/>
                { price.description }
              </p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="text-gold heading is-size-6">{ price.header1 }</p>
              <p className="text-gold title">
                <FontAwesomeIcon icon={ faCar }/>
                { price.plan }
              </p>
            </div>
          </div>
        </nav>,
        <hr className="style-six" />]
      ))
    ))
  );
};

PricingFrontPage.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      plan: PropTypes.string,
      header1: PropTypes.string,
      header2: PropTypes.string,
      header3: PropTypes.string,
      header4: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
      description2: PropTypes.string,
      items: PropTypes.array,
    })
  ),
}

export default PricingFrontPage
