import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import React from "react";
import PropTypes from "prop-types";

WhatsAppButton.propTypes = {heading: PropTypes.any, templateKey: PropTypes.any};

export function WhatsAppButton({heading, templateKey}) {
  return <div className={ "column" }>
    { (() => {
      if (templateKey === "index-page") {
        return <a href={ `https://wa.me/358401217084/?text=${ encodeURIComponent("Haluaisin tilata taksin,") }` +
        "%0a" + encodeURIComponent("Tilaajan nimi: ") +
        "%0a" + encodeURIComponent("Lähtöosoite: ") +
        "%0a" + encodeURIComponent("Kohdeosoite: ") +
        "%0a" + encodeURIComponent("Henkilömäärä: ")
        
        } className="button is-large bg-black has-text-white-ter is-full" id={'first-button'}>
          { heading }
          <FontAwesomeIcon id={'#first-button'} icon={ faWhatsapp } size={ "2x" } className={ "whatsapp-icon" } />
        </a>;
      } else {
        return <a href={ `https://wa.me/358401217084/?text=${ encodeURIComponent('I\'d like to order a taxi,') }` +
        '%0a' + encodeURIComponent('Orderer name: ') +
        '%0a' + encodeURIComponent('Starting address: ') +
        '%0a' + encodeURIComponent('Destination address: ') +
        '%0a' + encodeURIComponent('Number of people: ')
        
        } className="button is-large bg-black has-text-white-ter is-full">
          { heading }
          <FontAwesomeIcon icon={ faWhatsapp } size={ "2x" } className={ 'whatsapp-icon' } />
        </a>
      }
      
    })() }
  </div>;
}
