import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'
import MarkdownContent from "../components/MardownContent";
import PricingFrontPage from "../components/PricingFrontPage";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WhatsAppButton } from "../components/WhatsAppButton";
import { NumberHelperText } from "../components/NumberHelperText";
import { AboutPageWhatsAppButton } from "../components/AboutPageWhatsAppButton";


export const IndexPageTemplate = ({
                                    image,
                                    title,
                                    heading,
                                    phone,
                                    productlink,
                                    blogheader,
                                    templateKey,
                                    bloglink,
                                    intro,
                                    pricing,
                                    whatsappText,
                                  }) => (
  <div>
    <div
      className="hero is-fullheight-with-navbar margin-top-0 animated fadeIn"
      style={ {
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        lineHeight: '1',
        justifyContent: 'center',
        alignItems: 'center',
      } }
    >
      <div
        style={ {
          display: 'flex',
          lineHeight: '1',
          justifyContent: 'center',
          alignItems: 'center',
        } }
        className={ 'is-fullheight-with-navbar' }
      >
        
        <section className="section section--gradient animated fadeInUp">
          
          <div className="columns is-centered">
            <div className="column">
              <div className={ "column" }>
                <a href={ `tel:${ phone.replace(" ", "") }` } className="button is-large bg-black has-text-white-ter is-full">
                  { title } { phone }
                  <FontAwesomeIcon icon={ faPhone } className={ 'whatsapp-icon' } />
                </a></div>
              <div className={ "column has-text-centered" }>
                <NumberHelperText templateKey={ templateKey }/>
              </div>
            </div>
            <div className="column ml-5">
              <WhatsAppButton heading={ heading } templateKey={ templateKey } />
              <div className={ "column has-text-centered" }>
                  <span className={ 'has-text-white-ter' }>
                  
                  </span>
              </div>
            </div>
          </div>
        </section>
      
      </div>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                
                <div className="columns has-background-black" id={'pricing-table'}>
                  <div className="column is-10 is-offset-1">
                    
                    <h2 className="has-text-weight-semibold has-text-logo is-size-12 has-text-centered pb-5 text-gold">
                      <MarkdownContent content={ pricing.heading } />
                    </h2>
                    <hr className="style-six"></hr>
                    <PricingFrontPage data={ pricing.plans } />
                    <h3 className="has-text-weight-semibold has-text-logo is-size-12 has-text-centered pb-5 text-gold">
                      <span>
                        {pricing.description}
                      </span>
                    </h3>
                  </div>
                </div>
                
                <Features gridItems={ intro.blurbs } />
                <div className="columns">
                  <div className="column is-12 has-text-centered text-wrap">
                    <Link className="btn" to="#pricing-table" id={'pricing-link'}>
                      { productlink }
                    </Link>
                  </div>
                </div>
                <div className="column is-12 has-text-centered">
                  <BlogRoll />
                </div>
                <div className="column is-12 has-text-centered">
                  <AboutPageWhatsAppButton heading={whatsappText}/>
                </div>
                <div className="column is-12 has-text-centered">
                  <Link className="btn mt-4" to="#top-logo">
                    { bloglink }
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  phone: PropTypes.string,
  productlink: PropTypes.string,
  templateKey: PropTypes.string,
  blogheader: PropTypes.string,
  bloglink: PropTypes.string,
  whatsappText: PropTypes.string,
  pricing: PropTypes.shape({
    plans: PropTypes.array,
  }),
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({data}) => {
  const {frontmatter} = data.markdownRemark
  
  return (
    <Layout>
      <IndexPageTemplate
        image={ frontmatter.image }
        templateKey={ frontmatter.templateKey }
        title={ frontmatter.title }
        heading={ frontmatter.heading }
        phone={ frontmatter.phone }
        intro={ frontmatter.intro }
        productlink={ frontmatter.productlink }
        blogheader={ frontmatter.blogheader }
        bloglink={ frontmatter.bloglink }
        pricing={ frontmatter.pricing }
        whatsappText={ frontmatter.whatsappText }
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
    query IndexPageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
            frontmatter {
                templateKey
                title
                productlink
                blogheader
                bloglink
                whatsappText
                image {
                    childImageSharp {
                        fluid(maxWidth: 1280, maxHeight:1280, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                heading
                phone
                intro {
                    blurbs {
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1200, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        text
                    }
                }
                pricing {
                    description
                    heading
                    plans {
                        description
                        description2
                        plan
                        price
                        header1
                        header2
                        header3
                        header4
                    }
                }
            }
        }
    }
`
